import { NgModule } from '@angular/core';
import { canActivate, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthComponent, JoinOrgComponent, LoginComponent, RegisterComponent, ResetPasswordComponent } from './auth';
import { AuthGuard, RoleGuard } from './core/auth';
import { ErrorComponent } from './error/error.component';

// Use @angular/fire redirect methods
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/auth/login']);
const redirectLoggedInToAppDashboard = () => redirectLoggedInTo(['/app']);
// TODO: rename 'pages' url segment to 'app'

// TODO: Add RoleGuard to protect the main app routing when the user roles has no property as true
const routes: Routes = [
  // Auth Component (Eagerly Loaded)
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'join', component: JoinOrgComponent },
      { path: '', redirectTo: 'login', pathMatch: 'full' },
    ],
    // ...canActivate(redirectLoggedInToAppDashboard),
  },
  {
    path: 'app',
    loadChildren: () => import('./main-app/main-app.module').then((m) => m.MainAppModule),
    // ...canActivate(redirectUnauthorizedToLogin),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'pages',
  //   loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
  //   ...canActivate(redirectUnauthorizedToLogin),
  // },
  // Default path - TODO: attempt to handle routes into 'Pages'
  { path: 'pages', redirectTo: '/app/dashboard', pathMatch: 'prefix' },
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  { path: '404', component: ErrorComponent },
  { path: '**', redirectTo: '/auth/login' },
];

const routeConfig: ExtraOptions = {
  useHash: true,
  enableTracing: false,
  initialNavigation: 'enabledNonBlocking',
  // preloadingStrategy: PreloadAllModules,
  relativeLinkResolution: 'legacy',
};

@NgModule({
  // imports: [RouterModule.forRoot(routes, routeConfig)],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
