import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AppState } from '../../app.service';
import { AppVars } from '../../app.vars';
import { AuthService } from './auth.service';
import { FeatureModules } from './feature-map.service';

@Injectable()
export class FeatureAuthService {
  constructor(private auth: AuthService, private ngFireDb: AngularFireDatabase) {}

  getConfiguredModules(): Observable<FeatureModules> {
    // const orgCode = this.auth.getUserAccount()?.orgCode;
    return this.auth.user.pipe(
      switchMap((user) => {
        if (!user) {
          return of({});
        }
        const orgCode = user.orgCode;
        const orgConfigModulesPath = `provider-org-${orgCode}/0rg-metadata/modules`;
        // console.log('[FeatureAuthService] orgConfigModulesPath =', orgConfigModulesPath);
        return this.ngFireDb.object(orgConfigModulesPath).valueChanges();
      })
    );
  }

  isEnabled(moduleName: string): Observable<boolean> {
    this.auth.validateSession();
    const configuredModules: Observable<FeatureModules> = this.getConfiguredModules();
    return configuredModules.pipe(map((modules) => modules[moduleName] || false));
  }

  // Dashboard has Calendar, Patients/Callback, Referrals & Documents
  // check if any of these modules enabled and return the first enabled module
  getConfiguredDashboardModules(): Observable<string[]> {
    const configuredModules: Observable<FeatureModules> = this.getConfiguredModules();
    return configuredModules.pipe(map((modules) => Object.keys(modules).filter((key) => modules[key])));
  }
}
