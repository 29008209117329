import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './core/auth';

import { NetConnectionService } from './core/services/net-connection.service';

@Component({
  selector: 'mlc-offline-snack',
  template: `
    <mat-card>
      <mat-card-header>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
          <mat-icon mat-card-avatar color="warn">signal_wifi_off</mat-icon>
          <h2>You are offline!</h2>
        </div>
      </mat-card-header>

      <mat-card-content>
        <p>Please check your network connection! Connection to the Server is lost!</p>
        <p>This notification will go off when you are back online.</p>
      </mat-card-content>
    </mat-card>
  `,
  styles: [
    `
      .offline-snack {
        color: black;
        background-color: whitesmoke !important;
      }
      h2 {
        color: red;
        text-transform: uppercase;
      }
    `,
  ],
})
export class OfflineSnackComponent {}

/**
 * Main AppComponent for bootstrapping
 * This will also handle a forced logout of user session, on close of tab/window
 */
@Component({
  selector: 'mlc-root',
  template: ` <router-outlet></router-outlet> `,
  styles: [
    `
      .offline-snack {
        color: black;
        background-color: whitesmoke !important;
      }
    `,
  ],
})
export class AppComponent {
  networkStatus: Observable<boolean>;

  constructor(
    private auth: AuthService,
    private router: Router,
    private connectionService: NetConnectionService,
    private snackBar: MatSnackBar
  ) {
    this.networkStatus = this.connectionService.monitor();
    this.networkStatus.subscribe((status) => {
      if (!status) {
        this.snackBar.openFromComponent(OfflineSnackComponent, {
          panelClass: 'offline-snack',
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      } else {
        this.snackBar.dismiss();
      }
    });
    console.log('[AppComponent] should initialize AuthService!');
    console.log('[AppComponent] constructed:', new Date().toTimeString());
    this.router.navigate(['/']);
  }
}
