import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState } from './../../../app.service';
import { AppVars } from './../../../app.vars';

// TODO: Define an InboundRequest Interface
// TODO: Define the InboundRequest Status enum
// 0: new, 1: on-hold, 2: accepted, 3: rejected 4: cancelled
@Injectable()
export class InboxDataService {
  inboundRequestsPath: string;

  constructor(private ngDb: AngularFireDatabase, private _as: AppState) {
    this.inboundRequestsPath = `provider-org-${this._as.get(AppVars.ORG_CODE)}/inbound-requests`;
  }

  getInboundRequests(): Observable<any[]> {
    return this.ngDb
      .list(this.inboundRequestsPath, (ref) => ref.orderByChild('statusId').startAt(0).endAt(1))
      .snapshotChanges()
      .pipe(map((requests: any[]) => requests.map((req) => ({ id: req.key, ...req.payload.val() }))));
  }

  getAcceptedInboundRequests(): Observable<any[]> {
    return this.ngDb
      .list(this.inboundRequestsPath, (ref) => ref.orderByChild('statusId').equalTo(2))
      .snapshotChanges()
      .pipe(map((requests: any[]) => requests.map((req) => ({ id: req.key, ...req.payload.val() }))));
  }

  getRejectedInboundRequests(): Observable<any[]> {
    return this.ngDb
      .list(this.inboundRequestsPath, (ref) => ref.orderByChild('statusId').startAt(3))
      .snapshotChanges()
      .pipe(map((requests: any[]) => requests.map((req) => ({ id: req.key, ...req.payload.val() }))));
  }

  getNewInboundRequestCount(): Observable<number> {
    return this.ngDb
      .list(this.inboundRequestsPath, (ref) => ref.orderByChild('statusId').equalTo(0))
      .valueChanges()
      .pipe(map((items) => items.length));
  }

  updateInboundRequest(req: any): Promise<any> {
    const path = `${this.inboundRequestsPath}/${req.id}`;
    return this.ngDb.object(path).update(req);
  }

  acceptInboundRequest(id: string): Promise<any> {
    const updateObj = {
      id: id,
      status: 'accepted',
      statusId: 2,
      acceptedOn: new Date().toISOString(),
    };
    return this.updateInboundRequest(updateObj);
  }

  rejectInboundRequest(id: string): Promise<any> {
    const updateObj = {
      id: id,
      status: 'rejected',
      statusId: 3,
      rejectedOn: new Date().toISOString(),
    };
    return this.updateInboundRequest(updateObj);
  }

  holdInboundRequest(id: string): Promise<any> {
    const updateObj = {
      id: id,
      status: 'on-hold',
      statusId: 1,
      onHoldSince: new Date().toISOString(),
    };
    return this.updateInboundRequest(updateObj);
  }
}
