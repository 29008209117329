import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

import { AppState } from './../../app.service';
import { AppVars } from './../../app.vars';

const SEND_SMS = 'sendSMS';
@Injectable({ providedIn: 'root' })
export class SMSService {
  constructor(private afn: AngularFireFunctions, private _as: AppState) {}

  async sendSMS(clientId: string, phone: string, message: string): Promise<any> {
    return Promise.resolve();
    /*
    const sendSMS = this.afn.httpsCallable(SEND_SMS);
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const payload = { orgCode, clientId, phone, message };
    console.log('[SMSService] sendSMS():', payload);
    // Use the sendSMS cloud function to send a SMS notification message to the client
    try {
      const result = await sendSMS(payload).toPromise();
      if (result && result.status) {
        return Promise.resolve();
      } else {
        console.log('ERROR! [SMSService] sendSMS():', result?.error);
        return Promise.reject(result.error);
      }
    } catch (error) {
      console.log('ERROR! [SMSService] sendSMS():', error);
      return Promise.reject(error);
    }
    */
  }
}
