import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState } from './../../../app.service';
import { AppVars } from './../../../app.vars';

@Injectable()
export class OrderDataService {
  // orgCode: string;
  // basePath: string;
  // ordersPath: string;

  constructor(private ngDb: AngularFireDatabase, private _as: AppState) {
    // TODO: Obtain database paths from a standard common dbPath service
    // this.orgCode = this._as.get(AppVars.ORG_CODE);
    // this.ordersPath = `provider-org-${this.orgCode}/patient-orders`;
  }

  /**
   * Get all Actionable Orders in a List
   */
  getOrdersList(): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const ordersPath = `provider-org-${orgCode}/patient-orders`;
    // TODO: Also Map the patient key to Patient Name and Photo URL
    return this.ngDb
      .list(ordersPath)
      .snapshotChanges()
      .pipe(map((orders: any[]) => orders.map((order) => ({ id: order.key, ...order.payload.val() }))));
  }
}
