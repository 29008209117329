import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AppState } from './../../app.service';
import { AppVars } from './../../app.vars';
import { User } from './../../core/auth';
import { AuthService } from './../../core/auth/auth.service';

@Component({
  selector: 'mlc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  userAccountSubscription: Subscription;

  email: string;
  password: string;
  submitted = false;
  showProgress = false;
  ackMessage = '';
  errorMessage = '';
  verificationFailed = false;

  dashboardPath = 'app'; // '/pages/dashboard';
  joinOrgPath = '/auth/join';

  // TODO: Move all the 'User Feedback / Error Messages' into bundle for i18N implementation
  constructor(private authService: AuthService, private _as: AppState, private router: Router) {}

  ngOnInit() {
    // To avoid permission denied error post logout authService.user should be unsubscribed - ngOnDestroy()
    this.userAccountSubscription = this.authService.user.subscribe((user: User) => {
      this.showProgress = false;
      console.log('[LoginComponent]: init() user = ', user, new Date());
      if (user && !user.approved) {
        console.log('[LoginComponent]: init() user not approved!', user);
        this.authService.logout();
      }
      if (user) {
        this._as.set(AppVars.USER_ACCOUNT, user);
        this._as.set(AppVars.ORG_CODE, user.orgCode);
        // TODO: Re-implement this for PRAPP-68 - commented due to PRAPP-72
        // if (user.emailVerified) {
        const nextRoute = user.approved ? this.dashboardPath : '/auth/login'; // this.joinOrgPath;
        console.log('[LoginComponent]: init() next route path = ', nextRoute);
        this.router.navigate([nextRoute]);
        // } else {
        //   this.verificationFailed = true;
        //   this.errorMessage = 'Your registered email is not yet verified!';
        //   this.showProgress = false;
        //   this.submitted = false;
        // }
      }
    });
  }

  reset(): void {
    this.email = '';
    this.password = '';
    this.ackMessage = '';
    this.errorMessage = '';
    this.submitted = false;
    this.showProgress = false;
    this.verificationFailed = false;
    try {
      if (this.authService.getUserAccount()) {
        this.authService.logout();
      }
    } catch (error) {
      console.log('No user logged in yet.', error);
    }
  }

  submit(): void {
    if (!this.email || !this.password) {
      return;
    }
    this.showProgress = true;
    console.log('Email: ', this.email);
    this.submitted = true;
    this.errorMessage = '';
    this.ackMessage = '';

    this.authService
      .login(this.email, this.password)
      // .then((result) => {
      //   console.log('[LoginComponent] login() then result=', result);
      //   if (result.user && result.user.uid) {
      //     this.router.navigate(['/pages/dashboard']);
      //   }
      // })
      .catch((err) => {
        console.log('[LoginComponent] login() catch() now = ', new Date(), err);
        this.errorMessage = 'Cannot login, please check your credentials or network connection.';
        console.log('Login error: ', err);
        this.showProgress = false;
        this.submitted = false;
      });
  }

  resendVerificationEmail(): void {
    this.showProgress = true;
    this.submitted = true;
    this.errorMessage = '';
    this.ackMessage = '';
    this.authService
      .resendVerificationEmail()
      .then(() => {
        this.ackMessage = 'Verification link is sent to your registered email.';
        this.showProgress = false;
        this.submitted = false;
        this.authService.logout();
      })
      .catch((err) => {
        this.errorMessage = 'Cannot process your request, please contact your admin.';
        console.log('Verification Email Resend error: ', err.message);
        this.showProgress = false;
        this.submitted = false;
      });
  }

  /**
   * Tear down and unsubscribe to the User Account
   */
  ngOnDestroy() {
    if (this.userAccountSubscription) {
      console.log('[LoginComponent] onDestroy() before calling auth service unsubscribe()');
      this.userAccountSubscription.unsubscribe();
    }
    // Before destroying the Login Screen, check if user logged in push an audit log
    if (this._as.get(AppVars.USER_ACCOUNT)) {
      console.log('[LoginComponent] onDestroy() before calling auditLog()');
      this.authService.auditLog();
    }
  }
}
