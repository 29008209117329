import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { NotificationsDataService } from './services';
import { NotifyService } from './services/notify.service';
import { UserPresenceService } from './services/user-presence.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    // PatientDataService,
    // PatientDocsService,
    // AppointmentDataService,
    // // AuthService,
    // PatientRecordShareService,
    // PatientFormsService,
    // EncounterService,
    // OrderDataService,
    // InboxDataService,
    NotificationsDataService,
    UserPresenceService,
    NotifyService,
  ],
})
export class CoreModule {
  /* make sure CoreModule is imported only by one NgModule the AppModule */
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
