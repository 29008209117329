import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState } from './../../../app.service';
import { AppVars } from './../../../app.vars';

// TODO: Move this to a separate model class
interface MailMessage {
  from: string;
  to: string;
  cc: string;
  subject: string;
  content: string;
  replyTo?: string;
  sender?: string;
}

interface Payload {
  orgCode: string;
  clientId: string;
  message: MailMessage;
  doc: any;
}

// Named Cloud functions
const SEND_DOC_BY_EMAIL = 'sendDocByEmail';
const SEND_DOCS_TO_SIGN = 'amSyncSendDocsToSign';

@Injectable()
export class PatientDocsService {
  // orgCode: string;
  // patientsPath: string;

  constructor(private db: AngularFireDatabase, private _as: AppState, private afn: AngularFireFunctions) {
    // const orgCode = this._as.get(AppVars.ORG_CODE);
    // const patientsPath = `provider-org-${orgCode}/patients`;
  }

  /**
   * Returns the full path to be used to save documents in Storage for the given Patient
   * @param patientKey ID or key for the Patient
   */
  public getFileStoragePath(patientKey: string): string {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    return `provider-org-${orgCode}/filestore/${patientKey}`;
  }

  public getDocumentCollectionPath(patientKey: string): string {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    return `provider-org-${orgCode}/patients/${patientKey}/filestore`;
  }

  public getDocumentCategories(): Observable<string[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const docCategoriesPath = `provider-org-${orgCode}/document-categories`;
    return this.db.list<string>(docCategoriesPath).valueChanges();
  }

  /**
   * Documents List for a patient
   * @return Observable<any>
   */
  public getPatientDocsList(category?: string): Observable<any[]> {
    const patientKey = this._as.get(AppVars.SELECTED_PATIENT_KEY);
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    // Patient's Document File Store path is => /provider-org-<orgCode>/patients/<patientKey>/filestore
    const docsPath = `${patientsPath}/${patientKey}/filestore`;
    if (!category) {
      return this.db
        .list(docsPath)
        .snapshotChanges()
        .pipe(
          map((docs: any[]) => docs.map((doc) => ({ id: doc.key, ...doc.payload.val() }))),
          map((docs: any[]) => {
            // Filter out the documents which are marked { deleted: true }
            return docs.slice().filter((doc: any) => doc.deleted !== true);
          })
        );
    }
    const docsList = this.db
      .list(docsPath, (ref) => ref.orderByChild('category').equalTo(category))
      .snapshotChanges()
      .pipe(
        map((docs: any[]) => docs.map((doc) => ({ id: doc.key, ...doc.payload.val() }))),
        map((docs: any[]) => {
          // Filter out the documents which are marked { deleted: true }
          return docs.slice().filter((doc: any) => doc.deleted !== true);
        })
      );
    return docsList;
  }

  public updatePatientDocAuditTrail(docId: string, operation = 'read') {
    const patientKey = this._as.get(AppVars.SELECTED_PATIENT_KEY);
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    // Patient's Document File Store path is => /provider-org-<orgCode>/patients/<patientKey>/filestore
    const docsAuditPath = `${patientsPath}/${patientKey}/filestore/${docId}/auditTrail`;
    const user = this._as.get(AppVars.USER_ACCOUNT);
    const auditObj = {
      operation: operation,
      user: { uid: user.uid, displayName: user.displayName, email: user.email },
      timestamp: new Date().toISOString(),
    };
    return this.db.list(docsAuditPath).push(auditObj);
  }

  /**
   * Save the file record in Firebase Database corresponding to the file saved in Firebase Storage
   * @param patientKey Database key of the patient for whom the fileRecord to be updated
   * @param fileRecord FileStoreRecord object corresponding to the file saved in Firebase Storage
   * @param fileRecordKey Optional parameter when set, the specific file record would be updated
   */
  public saveFileRecord(patientKey: string, fileRecord: any, fileRecordKey?: string): any {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    const fileRecordPath = `${patientsPath}/${patientKey}/filestore`;
    if (fileRecordKey) {
      console.log('[PatientDocsService] saveFileRecord(): fileRecord with key =', fileRecord);
      return this.db.object(`${fileRecordPath}/${fileRecordKey}`).update(fileRecord);
      // .then(() => this.updatePatientDocAuditTrail(fileRecordKey, 'update'));
    } else {
      console.log('[PatientDocsService] saveFileRecord(): fileRecord=', fileRecord);
      return this.db.list(fileRecordPath).push(fileRecord);
      // .then(docId => this.updatePatientDocAuditTrail(docId, 'create'));
    }
  }

  // TODO: Improvise the use of this 'hack' to obtain last updated Imaging File Record
  public getLastImagingFileRecord(patientKey: string) {
    // const orgCode = this._as.get(AppVars.ORG_CODE);
    // const filestorePath = `provider-org-${orgCode}/patients/${patientKey}/filestore`;
    // return this.db.list(filestorePath, (ref) => ref.orderByChild('category').equalTo('Imaging').limitToLast(1)).snapshotChanges();
    return this.getLastFileRecord(patientKey, 'Imaging');
  }

  // TODO: Improvise the use of this 'hack' to obtain last updated Bill File Record
  public getLastBillsFileRecord(patientKey: string) {
    // const orgCode = this._as.get(AppVars.ORG_CODE);
    // const filestorePath = `provider-org-${orgCode}/patients/${patientKey}/filestore`;
    // return this.db.list(filestorePath, (ref) => ref.orderByChild('category').equalTo('Bills').limitToLast(1)).snapshotChanges();
    return this.getLastFileRecord(patientKey, 'Bills');
  }

  public getLastFileRecord(patientKey: string, category: string) {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const filestorePath = `provider-org-${orgCode}/patients/${patientKey}/filestore`;
    return this.db.list(filestorePath, (ref) => ref.orderByChild('category').equalTo(category).limitToLast(1)).snapshotChanges();
  }

  /**
   * Delete the file record in Firebase Database corresponding to the file saved in Firebase Storage
   * This will only mark the file record as { deleted: true }
   * @param patientKey Database key of the patient for whom the fileRecord to be deleted
   * @param fileRecordKey key of the specific file record
   */
  public deleteFileRecord(patientKey: string, fileRecordKey: string): Promise<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    if (!patientKey || !fileRecordKey) {
      return Promise.reject('Patient Key or File Record Key is missing.');
    } else {
      const fileRecordPath = `${patientsPath}/${patientKey}/filestore/${fileRecordKey}`;
      return this.db.object(fileRecordPath).update({ deleted: true });
      // .then(() => this.updatePatientDocAuditTrail(fileRecordKey, 'delete'));
    }
  }

  /**
   *
   * @param patientKey Database key of the patient for whom the fileRecord to be locked / unlocked
   * @param fileRecordKey key of the specific file record
   * @param unlockStatus - (optional) true / false, default is false
   */
  public lockFileRecord(patientKey: string, fileRecordKey: string, unlockStatus?: boolean): any {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    const fileRecordPath = `${patientsPath}/${patientKey}/filestore/${fileRecordKey}`;
    return this.db.object(fileRecordPath).update({ unlocked: unlockStatus ? unlockStatus : false });
    // .then(() => this.updatePatientDocAuditTrail(fileRecordKey, 'update'));
  }

  async sendDocByEmail(clientId: string, message: MailMessage, doc: any): Promise<any> {
    const sendDocAttachment = this.afn.httpsCallable(SEND_DOC_BY_EMAIL);
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const user = this._as.get(AppVars.USER_ACCOUNT);
    message.replyTo = `"${user.displayName}" <${user.email}>`;
    message.sender = user.displayName || user.email;
    const payload: Payload = { orgCode, clientId, message, doc };
    try {
      const result = await sendDocAttachment(payload).toPromise();
      if (result && result.status) {
        return Promise.resolve();
      } else {
        console.log('ERROR! [PatientDocsService] sendDocByEmail():', result?.error);
        return Promise.reject(result.error);
      }
    } catch (error) {
      console.log('ERROR! [PatientDocsService] sendDocByEmail():', error);
      return await Promise.reject(error);
    }
  }
}
