import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * GlobalState Service - to manage Top level application wide Event Subscription using RxJs
 * Consumers can subscribe to any defined events and consume the data stream
 * Publishers can notify data change against defined events anytime
 * Events can be defined using simple string names, so that the contract between Publishers & Subscribers
 * are maintained through common event names
 */
@Injectable()
export class GlobalState {

    private _data = new Subject<Object>();
    private _dataStream$ = this._data.asObservable();
    private _subscriptions: Map<string, Array<Function>> = new Map<string, Array<Function>>();

    // When the GlobalState object is constructed, setup the dataStream subscription
    constructor() {
        this._dataStream$.subscribe((data) => this._onEvent(data));
    }

    /**
     * Notify the event with the Data changed 'value'
     * This would enable the subscribers to receive the updated value in the stream
     * @param event Name or label of the event
     * @param value Corresponding value of the event
     */
    public notifyDataChanged(event, value) {
        const current = this._data[event];
        if (current !== value) {
            this._data[event] = value;

            this._data.next({
                event: event,
                data: this._data[event]
            });
        }
    }

    /**
     * Subscribe to the stream of 'event' triggers and use the 'callback' function
     * @param event Name or label of the event
     * @param callback Function to trigger on the event updates
     */
    public subscribe(event: string, callback: Function) {
        const subscribers = this._subscriptions.get(event) || [];
        subscribers.push(callback);
        this._subscriptions.set(event, subscribers);
    }

    /**
     * Upon triggering the registered event, do callback of all registered subscribers
     * @param data Corresponding data element set upon Event trigger
     */
    private _onEvent(data: any) {
        const subscribers = this._subscriptions.get(data['event']) || [];
        subscribers.forEach((callback) => {
            callback.call(null, data['data']);
        });
    }
}
