import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mlc-error',
  template: `

    <mat-card class="error-card">
      <mat-card-title>Oops...</mat-card-title>
      <mat-card-content>
      <p>There seems to be a problem, hence you've reached this page.</p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button color="warn" routerLink="/auth/login">LOGOUT</button>
      </mat-card-actions>
    </mat-card>

  `,
  styles: [`

    .error-card {
      margin: 30px;
      background-color: lightcyan;
    }

  `]
})
export class ErrorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
