import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
// import firebase from 'firebase/app';
import { Observable, of as observableOf } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

import { UserPresenceService } from '../services/user-presence.service';
import { AppState } from './../../app.service';
import { AppVars } from './../../app.vars';
import { User } from './auth.user';

import 'firebase/auth';

@Injectable()
export class AuthService {
  /**
   * User object from the /provider-users/${uid} node as an observable
   * AuthService.user is an observable available for other components to subscribe
   * When the user logs out, this would stream 'null' output
   */
  user: Observable<User | null>;
  // private fbAuth = firebase.auth();
  private userAccount: {
    uid: string;
    emailVerified: boolean;
    displayName: string;
    photoURL: string;
    lastLogin?: Date;
    [key: string]: any;
  };
  private usersPath = 'provider-users';
  private orgRoot = 'provider-org';

  /**
   * AuthService maps the logged in user with the User Node under /provider-users
   */
  constructor(
    private _as: AppState,
    private ngFireAuth: AngularFireAuth,
    private ngFireDb: AngularFireDatabase,
    private router: Router,
    private userPresence: UserPresenceService
  ) {
    console.log('[AuthService] Initialized!', new Date().toTimeString());
    this.user = this.ngFireAuth.user.pipe(
      switchMap((currentUser) => {
        if (currentUser) {
          return this.ngFireDb
            .object<User>(`${this.usersPath}/${currentUser.uid}`)
            .valueChanges()
            .pipe(
              map((user) => {
                // TODO: Check for allowed roles?
                this.userAccount = {
                  uid: currentUser.uid,
                  displayName: currentUser.displayName,
                  photoURL: currentUser.photoURL,
                  ...user,
                  emailVerified: currentUser.emailVerified,
                };
                // If the user's email is verified then update it to user's profile
                if (currentUser.emailVerified && !user.emailVerified) {
                  this.updateEmailVerification(currentUser.uid);
                }
                // console.log('[AuthService] db: User Account: ', this.userAccount);
                return this.userAccount;
              })
            );
          // TODO: Add user roles into the userAccount object obtained from usersPath/uid - use map post valueChanges()
        } else {
          // this._as.set(AppVars.USER_ACCOUNT, null);
          // this.router.navigate(['/']); // Now force the user to the login screen
          return observableOf(null);
        }
      })
    );
    this.user.subscribe((user) => {
      if (user) {
        this._as.set(AppVars.USER_ACCOUNT, user);
        this._as.set(AppVars.ORG_CODE, user.orgCode);
        // console.log('[AuthService] User Subscribed: User Account: ', user);
        // console.log('[AuthService] AppService get() OrgCode:', this._as.get(AppVars.ORG_CODE));
      } else {
        this._as.set(AppVars.USER_ACCOUNT, null);
        this._as.set(AppVars.ORG_CODE, null);
        this.router.navigate(['/']); // Now force the user to the login screen
      }
    });
  }

  /**
   * Logout the currently logged in user and remove the user account from the App State
   */
  public logout(): void {
    const now = new Date().toISOString();
    const uid = this.userAccount ? this.userAccount.uid : null;
    // this._as.set(AppVars.USER_ACCOUNT, null);
    // this._as.set(AppVars.ORG_CODE, null);
    this._as.reset();
    if (uid) {
      this.ngFireDb
        .object(`${this.usersPath}/${uid}/logs`)
        .update({ logged_out: now })
        .then(() => {
          // this.ngFireAuth.auth
          //   .signOut()
          this.userPresence
            .signOut()
            .then(() => {
              console.log(`[AuditLog] logout(): Logout Finished!`);
            })
            .catch((err) => {
              console.log('AuthService: Error while logging out - ', err.message);
            });
          console.log(`[AuthService] log_out update to provider-users/${uid}/logout: ${now}`);
        });
    }
  }

  /**
   * Get the provider user account's orgCode approval details
   * @param uid Optional UID for the user, default logged in UID is used
   */
  // public getUserAccount(uid?: string): Observable<any> {
  //   console.log('[AuthService] getUserAccount(): parameter uid = ', uid);
  //   const uidForPath = uid ? uid : this.userAccount.uid;
  //   const userNodePath = `${this.usersPath}/${uidForPath}`;
  //   console.log('[AuthService] getUserAccount(): userNodePath = ', userNodePath);
  //   if (!uidForPath) {
  //     throw new Error('[AuthService] - missing UID to get User Account!');
  //   }
  //   return this.ngFireDb.object(userNodePath).valueChanges();
  // }
  public getUserAccount() {
    return this.userAccount;
  }

  public validateSession(): void {
    this.ngFireAuth.currentUser.then((user) => user.getIdToken(true));
  }

  /**
   * Update the provider user account details under /provider-users/$uid node
   * @param updateObject orgCode: string; requestDate: Date; emailVerified: boolean
   */
  public updateUserAccount(updateObject: { orgCode: string; requestDate: Date; emailVerified: boolean }): Promise<any> {
    const userNodePath = `${this.usersPath}/${this.userAccount.uid}`;
    return this.ngFireDb.object(userNodePath).update(updateObject);
  }

  /**
   * Resend verification email to the current user's email ID
   */
  public async resendVerificationEmail(): Promise<any> {
    return (await this.ngFireAuth.currentUser).sendEmailVerification();
    // return this.fbAuth.currentUser.sendEmailVerification();
  }

  /**
   * Get the Organization metadata node
   * @param orgCode Organization Code to get the Org Data
   */
  public getOrgData(orgCode: string): Observable<any> {
    const orgPath = `${this.orgRoot}-${orgCode}/0rg-metadata`;
    return this.ngFireDb
      .object(orgPath)
      .valueChanges()
      .pipe(
        tap((org) => {
          if (org && org.name) {
            this._as.set(AppVars.ORG_NAME, org.name);
            this._as.set(AppVars.ORG_METADATA, org);
          }
        })
      );
  }

  /**
   * Update logged in user's password
   * @param password newPassword to be updated into users' auth account
   */
  public async updatePassword(password: string): Promise<any> {
    if (password && password.length > 0) {
      return (await this.ngFireAuth.currentUser).updatePassword(password);
      // return this.fbAuth.currentUser.updatePassword(password);
    } else {
      throw new Error('Error: Could not update password now. Try again later.');
    }
  }

  /**
   * Update user's Name, profile picture, phone
   * @param name Display Name of the user
   * @param photoURL (optional) URL of the profile picture/photo
   * @param phone (optional) phone number
   */
  public async updateName(name: string, photoURL?: string, phone?: string): Promise<any> {
    const updateObj = {
      displayName: name,
      photoURL: photoURL || this.userAccount.photoURL,
    };
    this.userAccount.displayName = name;
    // TODO: Make this an Behavior Subject value, so that other components can subscribe for changes
    this._as.set(AppVars.USER_ACCOUNT, this.userAccount);
    const currentUser = await this.ngFireAuth.currentUser;
    return currentUser.updateProfile(updateObj).then(() => {
      // return this.fbAuth.currentUser.updateProfile(updateObj).then(() => {
      if (phone) {
        updateObj['phone'] = phone;
      }
      this.ngFireDb.object(`${this.usersPath}/${this.userAccount.uid}`).update(updateObj);
    });
  }

  /**
   * Change / Update email (used for login) for the user
   * @param newEmail Updated email for the user
   */
  public async updateEmail(newEmail: string): Promise<any> {
    const currentUser = await this.ngFireAuth.currentUser;
    console.log('[AuthService] Attempting email update for: ', currentUser.email, ' with ', newEmail);
    return new Promise((resolve, reject) => {
      currentUser
        // this.fbAuth.currentUser
        .updateEmail(newEmail)
        .then(() => {
          // TODO: Check this ->Now the emailVerified should be false
          currentUser
            // this.fbAuth.currentUser
            .sendEmailVerification()
            .then(() => {
              resolve('Email updated & verification email sent!');
              this.logout();
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Sign In / Login the user with email & password
   * @param email registered email
   * @param password secured password
   */
  public login(email: string, password: string): Promise<any> {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password);
    // return this.fbAuth.signInWithEmailAndPassword(email, password);
  }

  /**
   * When users forgets password, then send Password Reset email
   * to the registered email ID of the user
   */
  public sendPasswordResetEmail(email: string) {
    // return this.fbAuth
    return this.ngFireAuth.sendPasswordResetEmail(email);
  }

  /**
   * Create/Register a new User and update /provider-users/${uid} node
   * @param email registered email ID for the user
   * @param password secured password
   */
  public createNewUser(email: string, password: string): Promise<any> {
    return (
      // this.fbAuth
      this.ngFireAuth
        .createUserWithEmailAndPassword(email, password)
        // TODO: Move this to functions and keep this function a simple call
        .then((userCredential) => {
          console.log('[AuthService] createNewUser(): res = ', userCredential.user);
          const newUser = {
            uid: userCredential.user.uid,
            emailVerified: userCredential.user.emailVerified,
            email: userCredential.user.email,
            orgCode: '',
            approved: false,
            createdOn: new Date(),
          };
          console.log('[AuthService] createNewUser(): newUser = ', newUser);

          // TODO: Move this job to firebase function & secure provider-users node with rules
          // Add this new user to the 'provider-users' node
          if (newUser && newUser.uid) {
            this.addNewProviderUser(newUser);
          }
        })
        .catch((error) => {
          console.log('[AuthService] Error while creating new user with Email: ', email, error);
          this.logout();
          throw new Error('Error creating user with email: ' + email + ': ' + error.message);
        })
    );
  }

  public auditLog(): void {
    const now = new Date().toISOString();
    // TODO: Audit log the login attempt into /provider-users/${uid}/lastLogin with timestamp
    console.log('[AuditLog] userAccount = ', this.userAccount);
    // console.log(`[AuditLog] Update to provider-users/${this.userAccount.uid}/last_login: ${now}`);
    if (this.userAccount && this.userAccount.uid) {
      this.ngFireDb.object(`${this.usersPath}/${this.userAccount.uid}/logs`).update({ last_login: now });
    }
  }

  private addNewProviderUser(newUser: User): Promise<void> {
    return this.ngFireDb
      .object(`${this.usersPath}/${newUser.uid}`)
      .update(newUser)
      .then(async () => {
        console.log('[AuthService] update(newUser): updated to the provider-users node. ', newUser);
        // Now send the email verification & logout
        // this.fbAuth.currentUser
        (await this.ngFireAuth.currentUser).sendEmailVerification().then(() => {
          console.log('User created successfully verfication mail sent for email: ' + newUser.email);
          this.logout();
        });
      });
  }

  private updateEmailVerification(uid: string): Promise<void> {
    return this.ngFireDb.object(`${this.usersPath}/${uid}`).update({ emailVerified: true });
  }
}
