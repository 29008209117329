import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';

import { AppState } from './../../../app.service';
import { AppVars } from './../../../app.vars';

@Injectable()
export class PatientFormsService {
  // private providerOrgRoot: string;
  // private formsBasePath: string;
  // private patientDataBasePath: string;
  // private vitalsBasePath: string;
  // private peBasePath: string;
  // private dxBasePath: string;
  // private hsBasePath: string;

  constructor(private ngFireDb: AngularFireDatabase, private _as: AppState) {
    // const orgCode = _as.get(AppVars.ORG_CODE);
    // this.providerOrgRoot = `provider-org-${orgCode}`; // ex: provider-org-XYZ456
    // this.formsBasePath = `${this.providerOrgRoot}/dyna-forms`; // Patient Forms
    // this.patientDataBasePath = `${this.providerOrgRoot}/patients`;
    // this.vitalsBasePath = `${this.providerOrgRoot}/vital-signs`; // This is not used TODO: Remove this
    // this.peBasePath = `${this.providerOrgRoot}/physical-exams`; // Physical Exam forms
    // this.dxBasePath = `${this.providerOrgRoot}/diagnosis-forms`; // Diagnosis & Plan forms
    // this.hsBasePath = `${this.providerOrgRoot}/history-forms`; // Patient Injury History forms
    // TODO: Define further forms for Orders, Procedures, Approvals?
  }

  /**
   * Get the list of Patient Forms
   * @return Observable<any>
   */
  public getFormsList(): Observable<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    // return this.ngFireDb.list(`provider-org-${orgCode}/dyna-forms`).valueChanges();
    return this.ngFireDb.list(`provider-org-${orgCode}/patient-data-forms`).valueChanges();
  }

  /**
   * Get the list of Patient Agreements
   * @return Observable<any[]>
   */
  public getAgreementsList(): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    return this.ngFireDb.list(`provider-org-${orgCode}/intake-agreements`).valueChanges();
  }

  // TODO: Remove individual reference for Vitals, since it is embedded inside Physical Exams
  /**
   * Get the list of Vital Signs
   * @return Observable<any>
   */
  // public getVitalsList(): Observable<any> {
  //   return this.ngFireDb.list(this.vitalsBasePath).valueChanges();
  // }

  /**
   * Get the list of Physical Exam Forms
   * @return Observable<any>
   */
  public getPeFormsList(): Observable<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    return this.ngFireDb.list(`provider-org-${orgCode}/physical-exams`, (ref) => ref.orderByChild('order')).valueChanges();
  }

  /**
   * Get the list of Diagnosis Forms
   * @return Observable<any>
   */
  // public getDxFormsList(): Observable<any> {
  //   return this.ngFireDb.list(this.dxBasePath).valueChanges();
  // }

  /**
   * Get the list of History Forms
   * @return Observable<any>
   */
  public getHsFormsList(): Observable<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    return this.ngFireDb.list(`provider-org-${orgCode}/history-forms`, (ref) => ref.orderByChild('order')).valueChanges();
  }

  /**
   * Get the list of Data items for the Forms filled by Patient
   * @return Observable<any>
   */
  public getFormsDataList(patientKey: string): Observable<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    return this.ngFireDb.list(`provider-org-${orgCode}/patients/${patientKey}/forms`).valueChanges();
  }

  /**
   * Get the patient filled Form data object
   * @param patientKey - String
   * @param formName - String
   * @return Observable<any>
   */
  public getPatientFormData(patientKey: string, formName: string): Observable<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    if (patientKey === null || formName === null) {
      return null;
    }
    const formDataPath = `provider-org-${orgCode}/patients/${patientKey}/forms/${formName}`;
    return this.ngFireDb.object(formDataPath).valueChanges();
  }

  // TODO: Verify whether this method is really required - getEncounterFormsDataList() - currently not used
  /**
   * Get the list of Data items filled for the Forms during the encounter
   * @param patientKey - Patient Key Reference
   * @param encounterKey - Encounter Key Reference - same as the Appointment ID or Key
   * @return Observable<any>
   */
  public getEncounterFormsDataList(patientKey: string, encounterKey: string): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    return this.ngFireDb.list(`provider-org-${orgCode}/patients/${patientKey}/encounters/${encounterKey}/form-data`).valueChanges();
  }

  /**
   * Save the filled up form data & update the patient node
   * @param payload payload: Form Data payload as JSON object
   * @return Promise
   */
  public saveFormData(patientKey: string, formName: string, payload: any): Promise<void> {
    const orgCode = this._as.get(AppVars.ORG_CODE);

    // TODO: Protect db update from non object payload values
    const formData = { ...{ _lastUpdated: new Date().toISOString() }, ...payload }; // Clone the payload and Add the last updated timestamp
    console.log('[PatientFormsService]: payload=>', formData);
    const formDataPath = `provider-org-${orgCode}/patients/${patientKey}/forms/${formName}`;
    return this.ngFireDb.object(formDataPath).update(formData);
  }

  /**
   * Mark the patient data forms as verified
   * @param patientKey - String
   * @param verified - boolean true/false (default is true)
   */
  public verifyPatientFormsData(patientKey: string, verified: boolean = true): Promise<void> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const formsVerificationPath = `provider-org-${orgCode}/patients/${patientKey}`;
    const updateObj = { formsVerified: verified };
    return this.ngFireDb.object(formsVerificationPath).update(updateObj);
  }

  /**
   * Get the patient forms data verification status
   * @param patientKey - String
   */
  public getFormsVerificationStatus(patientKey: string): Observable<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const formsVerificationPath = `provider-org-${orgCode}/patients/${patientKey}/formsVerified`;
    return this.ngFireDb.object(formsVerificationPath).valueChanges();
  }

  // TODO: Remove individual reference for Vitals, since it is embedded inside Physical Exams
  /**
   * Mark the patient vitals data as completed
   * @param patientKey - String
   * @param completed - boolean true/false (default is true)
   */
  // public setVitalsCompletionStatus(patientKey: string, completed: boolean = true): Promise<any> {
  //   const vitalsCompletionPath = `${this.patientDataBasePath}/${patientKey}`;
  //   const updateObj = { vitalsCompleted: completed };
  //   return this.ngFireDb.object(vitalsCompletionPath).update(updateObj);
  // }

  /**
   * Get the patient vitals data completion status
   * @param patientKey - String
   */
  // public getVitalsCompletionStatus(patientKey: string): Observable<any> {
  //   const vitalsCompletionPath = `${this.patientDataBasePath}/${patientKey}/vitalsCompleted`;
  //   return this.ngFireDb.object(vitalsCompletionPath).valueChanges();
  // }

  // TODO: This should now be part of encounter status and not Patient status
  /**
   * Mark the patient exam data as completed
   * @param patientKey - String
   * @param completed - boolean true/false (default is true)
   */
  public setPeCompletionStatus(patientKey: string, completed: boolean = true): Promise<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const peCompletionPath = `provider-org-${orgCode}/patients/${patientKey}`;
    const updateObj = { peCompleted: completed };
    return this.ngFireDb.object(peCompletionPath).update(updateObj);
  }

  /**
   * Get the patient exam data completion status
   * @param patientKey - String
   */
  public getPeCompletionStatus(patientKey: string): Observable<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const peCompletionPath = `provider-org-${orgCode}/patients/${patientKey}/peCompleted`;
    return this.ngFireDb.object(peCompletionPath).valueChanges();
  }

  /**
   * Mark the patient exam data as completed
   * @param patientKey - String
   * @param completed - boolean true/false (default is true)
   */
  public setHsCompletionStatus(patientKey: string, completed: boolean = true): Promise<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const hsCompletionPath = `provider-org-${orgCode}/patients/${patientKey}`;
    const updateObj = { historyCompletedOn: completed ? new Date().toISOString() : null };
    return this.ngFireDb.object(hsCompletionPath).update(updateObj);
  }

  /**
   * Get the patient exam data completion status
   * @param patientKey - String
   */
  public getHsCompletionStatus(patientKey: string): Observable<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const hsCompletionPath = `provider-org-${orgCode}/patients/${patientKey}/historyCompletedOn`;
    return this.ngFireDb.object(hsCompletionPath).valueChanges();
  }

  /**
   * Get the patient's encounter filled Form data object
   * @param patientKey Patient Key reference
   * @param encounterKey Encounter Key reference (same as Appointment ID or Key)
   * @param formName - form name which is used as ID or Key
   * @return Observable<any>
   */
  public getEncounterFormData(patientKey: string, encounterKey: string, formName: string): Observable<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    if (patientKey === null || formName === null || encounterKey === null) {
      return null;
    }
    const formDataPath = `provider-org-${orgCode}/patients/${patientKey}/encounters/${encounterKey}/form-data/${formName}`;
    return this.ngFireDb.object(formDataPath).valueChanges();
  }

  /**
   * Save the filled up form data of the encounter & update the patient's encounter node
   * @param patientKey Patient Key reference
   * @param encounterKey Encounter Key reference (same as Appointment ID)
   * @param formName Unique name of the form (also used as form ID or Key)
   * @param payload payload: Form Data payload as JSON object
   * @return Promise
   */
  public saveEncounterFormData(patientKey: string, encounterKey: string, formName: string, payload: any): Promise<void> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    // TODO: Protect db update from non object payload values
    const formData = { ...{ _lastUpdated: new Date().toISOString() }, ...payload }; // Clone the payload & Add the last updated timestamp
    console.log('[PatientFormsService]: payload=>', formData);
    const encounterFormDataPath = `provider-org-${orgCode}/patients/${patientKey}/encounters/${encounterKey}/form-data/${formName}`;
    return this.ngFireDb.object(encounterFormDataPath).update(formData);
  }

  public getPDFServiceAuthConfig() {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const printServiceConfigPath = `provider-org-${orgCode}/0rg-metadata/integration/print-service`;
    return this.ngFireDb.object(printServiceConfigPath).valueChanges();
  }
}
