import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import firebase from 'firebase/app';
import { tap, map, switchMap, first } from 'rxjs/operators';
import { of as ObservableOf } from 'rxjs';
import 'firebase/database';
import 'firebase/auth';
// UserPresenceService will handle the user's status as online/away/offline
// the status is persisted at the /provider-users/{uid}/presence node
// presence is persisted as { status: online | away | offline, timestamp: timestampValue }
// Use this node to verify any users online/away/offline status

// Possible status cases
// 1. Signed-in and using app (online 💚)
// 2. Signed-in but app is closed (offline 🔴)
// 3. Signed-in but on a different browser tab (away 💤)
// 4. Signed-out but app is still opened (offline 🔴)
// 5. Signed-out and app closed (offline 🔴)

@Injectable()
export class UserPresenceService {
  private providerUsers = 'provider-users';
  constructor(private afAuth: AngularFireAuth, private db: AngularFireDatabase) {
    console.log('User online presence is tracked!');
    this.updateOnUser().subscribe();
    // this.updateOnDisconnect().subscribe(); // TODO: Throws PERMISSION Error - verify the sequence on disconnect
    this.updateOnAway();
  }

  getPresence(uid: string) {
    return this.db.object(`${this.providerUsers}/${uid}/presence`).valueChanges();
  }

  getUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  async setPresence(status: string) {
    const user = await this.getUser();
    if (user) {
      return this.db.object(`${this.providerUsers}/${user.uid}/presence`).update({ status, timestamp: this.timestamp });
    }
  }

  get timestamp() {
    return firebase.database.ServerValue.TIMESTAMP;
  }

  // Updates status when logged-in connection to Firebase starts
  updateOnUser() {
    const connection = this.db
      .object('.info/connected')
      .valueChanges()
      .pipe(map((connected) => (connected ? 'online' : 'offline')));

    return this.afAuth.authState.pipe(
      switchMap((user) => (user ? connection : ObservableOf('offline'))),
      tap((status) => this.setPresence(status))
    );
  }

  // updates user as offline - case 2
  updateOnDisconnect() {
    return this.afAuth.authState.pipe(
      tap((user) => {
        if (user) {
          this.db.object(`status/${user.uid}`).query.ref.onDisconnect().update({
            status: 'offline',
            timestamp: this.timestamp,
          });
        }
      })
    );
  }

  // TODO: Test correctness of User Presence updates to the db
  async signOut() {
    await this.setPresence('offline');
    await firebase.auth().signOut();
  }

  // User navigates to a new tab, case 3
  updateOnAway() {
    document.onvisibilitychange = (e) => {
      document.visibilityState === 'hidden' ? this.setPresence('away') : this.setPresence('online');
    };
  }
}
