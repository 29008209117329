<div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="auth-box">
  <!--Header-->
  <div fxFlex="20" fxLayoutAlign="center center" class="header">
    <a routerLink="/app-login">
      <!-- <img sizes="240px" src="../../assets/images/MLC-logo2x.png" srcset="../../assets/images/MLC-logo2x-p-500.png 500w, ../../assets/images/MLC-logo2x.png 542w"
        width="240" alt="MedLegalConnect"/> -->
      <img src="/assets/images/om_logo_full_h.svg" alt="OrganizeMED" width="340" />
    </a>
  </div>

  <!--Auth Child Component Outlet-->
  <div fxFlex>
    <router-outlet></router-outlet>
  </div>

  <!--Footer-->
  <footer fxFlex="5" class="footer">
    <div class="copyright">© {{ dateValue | date: 'yyyy' }} OrganizeMED by Pranava Inc. All rights reserved.</div>
  </footer>
</div>
