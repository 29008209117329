import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { isSameDay } from 'date-fns';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { AppState } from './../../../app.service';
import { AppVars } from './../../../app.vars';
import { AppointmentStatus } from './../appointment-data/appointment';

/**
 * EncounterService - Injectable service class to handle data transactions related to encounters
 * Open & Close an encounter
 * Provide current encounter ID - which is equivalent to the Appointment ID for which the patient is checked-in
 * Provide the list of encounters for the patient
 */
@Injectable()
export class EncounterService {
  // providerOrgRoot: string;

  constructor(private db: AngularFireDatabase, private _as: AppState) {
    // this.providerOrgRoot = `provider-org-${this._as.get(AppVars.ORG_CODE)}`;
    // Encounters data path: {providerOrgRoot}/patients/{patientKey}/encounters
  }

  /**
   * Get all encounters array for the patient
   * Each encounter node comprises the form-data collected during the encounter
   * @param patientKey - Patient Key reference
   */
  getAllEncounters(patientKey: string): Observable<any[]> {
    const providerOrgRoot = `provider-org-${this._as.get(AppVars.ORG_CODE)}`;
    const encountersPath = `${providerOrgRoot}/patients/${patientKey}/encounters`;
    console.log('[EncounterService] getAllEncounters(): path = ', encountersPath);
    // list the encounters by their (appointment's) start date
    return this.db
      .list(encountersPath, (ref) => ref.orderByChild('start'))
      .snapshotChanges()
      .pipe(map((encounters) => encounters.map((encounter: any) => ({ id: encounter.key, ...encounter.payload.val() }))));
  }

  /**
   * Get the specific encounter data object for the patient
   * The encounter node comprises form-data array along with encounter properties
   * @param patientKey - Patient Key reference
   * @param appointmentKey - Appointment Key reference of the encounter
   */
  getEncounter(patientKey: string, appointmentKey: string): Observable<any> {
    const providerOrgRoot = `provider-org-${this._as.get(AppVars.ORG_CODE)}`;
    const encountersPath = `${providerOrgRoot}/patients/${patientKey}/encounters/${appointmentKey}`;
    return this.db.object(encountersPath).valueChanges();
  }

  /**
   * Create or Open the Encounter, when the Patient Checks in on a Scheduled appointment
   * @param patientKey - Patient Key reference
   * @param appointmentKey - Appointment Key reference for the encounter
   * @param startDate - Scheduled appointment start Date
   * @param notes - (optional) Encounter / Visit notes
   */
  async openEncounter(patientKey: string, appointmentKey: string, startDate: any, notes?: string, lastEncounterKey?: string): Promise<any> {
    const providerOrgRoot = `provider-org-${this._as.get(AppVars.ORG_CODE)}`;

    // If there is a lastEncounterKey specified (the component has to check if it is for the same day or not)
    // Update the encounter
    const encountersPath = `${providerOrgRoot}/patients/${patientKey}/encounters/${lastEncounterKey || appointmentKey}`;
    console.log('[EncounterService] openEncounter() path = ', encountersPath);
    return this.db.object(encountersPath).update({
      // id: appointmentKey,
      start: startDate,
      notes: notes || '',
    });
  }

  getEncounterForDate(patientKey: string, startDate: Date | string): Observable<any> {
    const refStartDate = new Date(startDate);
    refStartDate.setHours(0, 0, 0, 0);
    const refEndDate = new Date(startDate);
    refEndDate.setHours(23, 59, 59, 59);
    const providerOrgRoot = `provider-org-${this._as.get(AppVars.ORG_CODE)}`;
    const encountersPath = `${providerOrgRoot}/patients/${patientKey}/encounters`;
    return this.db
      .list(encountersPath, (ref) => ref.orderByChild('start').startAfter(refStartDate.toISOString()).endAt(refEndDate.toISOString()))
      .snapshotChanges()
      .pipe(
        map((encounters) => encounters.map((encounter: any) => ({ id: encounter.key, ...encounter.payload.val() }))),
        map((encounters) => (encounters && encounters.length > 0 ? encounters[0] : null))
      );
  }

  deleteEncounter(patientKey: string, appointmentKey: string, startDate: any): Promise<any> {
    // TODO: Delete the Encounter / Visit - what if there were more than one appointments for the same day?
    const providerOrgRoot = `provider-org-${this._as.get(AppVars.ORG_CODE)}`;
    const encountersPath = `${providerOrgRoot}/patients/${patientKey}/encounters/${appointmentKey}`;
    console.log('[EncounterService] openEncounter() path = ', encountersPath);
    return this.db.object(encountersPath).remove();
  }

  /**
   * Update the Encounter / Visit notes
   * @param patientKey - Patient Key reference
   * @param appointmentKey - Appointment Key reference
   * @param notes - Encounter / Visit notes
   */
  updateEncounterNotes(patientKey: string, appointmentKey: string, notes: string): Promise<any> {
    const providerOrgRoot = `provider-org-${this._as.get(AppVars.ORG_CODE)}`;
    const encountersPath = `${providerOrgRoot}/patients/${patientKey}/encounters/${appointmentKey}`;
    return this.db.object(encountersPath).update({ notes: notes ? notes : null });
  }

  /**
   * Get the current Encounter or Appointment ID for which the Patient has checked in
   * @param patientId - key/ID of the Patient upon which the appointments are aggregated
   */
  getCurrentEncounterId(patientId: string): Observable<any> {
    const providerOrgRoot = `provider-org-${this._as.get(AppVars.ORG_CODE)}`;
    const patientAppointmentsPath = `${providerOrgRoot}/patient-appointments/${patientId}`;
    return this.db
      .list(patientAppointmentsPath, (ref) => ref.orderByChild('statusId').equalTo(AppointmentStatus.CHECKED_IN))
      .snapshotChanges()
      .pipe(
        map((appointments) => appointments.map((appointment: any) => ({ id: appointment.key, ...appointment.payload.val() }))),
        switchMap((appointments: any[]) => {
          if (appointments) {
            // Verify: There should practically be 1 or 0 appointment object in the array
            // with statusId == CHECKED_IN, if not it is ERROR
            if (appointments.length > 1) {
              throw new Error('Error: More than one encounter CHECKED IN.');
            }
            return appointments[0].id;
          } else {
            return null;
          }
        }) // end of switchMap
      ); // end of pipe
  }
}
