import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production || environment.uat) {
  enableProdMode();
  // Suppress any console outputs for production builds
  window.console.log = function() {};
  window.console.error = function() {};
  window.console.info = function() {};
  window.console.warn = function() {};
  window.console.trace = function() {};
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
