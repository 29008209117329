import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';

import { AppState } from './../../../app.service';
import { AppVars } from './../../../app.vars';

/**
 * Patient Record Share Data Service
 * handling all the Record Sharing related data List, Save & Update
 */
@Injectable()
export class PatientRecordShareService {
  // orgCode: string;
  // basePath: string;
  // patientsPath: string;
  // indiProvidersPath: string;
  // favoriteProvidersPath: string;

  constructor(private _as: AppState, private ngFireDb: AngularFireDatabase) {
    // this.orgCode = _as.get(AppVars.ORG_CODE);
    // this.basePath = `provider-org-${this.orgCode}`;
    // this.patientsPath = `${this.basePath}/patients`; // => /provider-org-<orgCode>/patients
    // this.indiProvidersPath = 'indi-providers'; // base Path of Independent Providers who'd have their shared Patient record data nodes
    // this.favoriteProvidersPath = `${this.basePath}/favorite-providers`; // store all fav providers
  }

  /**
   * Get the list of all the record shares done for the Patient
   * @param patientKey Patient Key
   */
  public getRecordShares(patientKey: string): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const sharedWithPath = `provider-org-${orgCode}/patients/${patientKey}/recordSharedWith`;
    return this.ngFireDb.list(sharedWithPath).valueChanges();
  }

  /**
   * Save the record share, when a Patient's record is shared with another Provider
   * @param patientKey Patient Key
   * @param sharedWith Provider profile object related to whom the Patient is referred to/shared with
   */
  public saveRecordShare(patientKey: string, sharedWith: any): Promise<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const sharedWithPath = `provider-org-${orgCode}/patients/${patientKey}/recordSharedWith`;
    return this.ngFireDb
      .list(sharedWithPath)
      .update(sharedWith.phone, sharedWith)
      .then(res => {
        this.updateProviderProfile(sharedWith);
      })
      .catch(err => {
        console.log('[PatientRecordShareService] saveReferral(): Error! ', err.message);
        throw new Error(err.message);
      });
  }

  /**
   * Get the list of Favorite Providers used for frequent Record Shares
   */
  public getFavoriteProviders(): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    return this.ngFireDb.list(`provider-org-${orgCode}/favorite-providers`).valueChanges();
  }

  /**
   * Save a Provider into Favorite List for frequent Record Shares
   * @param provider - Favorite Provider object
   */
  public saveFavoriteProvider(provider): any {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    return this.ngFireDb.list(`provider-org-${orgCode}/favorite-providers`).push(provider);
  }

  // TODO: rework the logic
  /**
   * Update the sharedWith node with Shared Patient Data
   * when a record share is added
   * @param patientKey - Key of the Patient
   * @param providerProfile - Shared With Independent Provider's Profile object
   */
  private updateProviderProfile(providerProfile: any): Promise<any> {
    // use the referral.phone as the key to store the object
    const indiProvider = {
      name: providerProfile.name,
      phone: providerProfile.phone,
      email: providerProfile.email,
      facility: providerProfile.facility
    };
    // TODO: update Indi-Provider's profile, only if it is not set earlier
    console.log('[PatientRecordShareService] - updating only provider profile and not duplicating referral reference');
    return this.ngFireDb.list(`indi-providers/${providerProfile.phone}`).update('profile', indiProvider);
    // Note: serverless-functions adds the reference object into indi-providers/{phone} node
  }
}
