import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/performance';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, OfflineSnackComponent } from './app.component';
import { AppState } from './app.service';
import { AppVars } from './app.vars';
import { AuthComponent } from './auth/auth.component';
import { JoinOrgComponent } from './auth/join-org/join-org.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { CoreModule } from './core';
import { AuthService } from './core/auth';
import { AuthGuard } from './core/auth/auth.guard';
import { ErrorComponent } from './error/error.component';
import { GlobalState } from './global.state';
import { UserStatusComponent } from './user-status.component';

import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/auth';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/database';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/functions';

// TODO: Simplify this by moving Auth components into AuthModule and import material stuff in AuthModule

// Global Provider Application wide - TODO: Refactor using ngrx/store
const APP_PROVIDERS = [GlobalState, AppVars];
const AUTH_PROVIDERS = [AuthService, AuthGuard];

const EMULATOR_PROVIDERS = [
  { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9099] : undefined },
  { provide: USE_DATABASE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9001] : undefined },
  { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8081] : undefined },
  { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
];

const firebaseDb = environment.db;
const dbConfig = firebaseDb.provider ? { ...firebaseDb.config, ...firebaseDb.provider } : firebaseDb.config;

@NgModule({
  declarations: [
    AppComponent,
    OfflineSnackComponent,
    UserStatusComponent,
    LoginComponent,
    ResetPasswordComponent,
    RegisterComponent,
    ErrorComponent,
    AuthComponent,
    JoinOrgComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CoreModule,
    BrowserAnimationsModule,
    OverlayModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatCardModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    FlexLayoutModule,
    AngularFireModule.initializeApp(dbConfig),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFirePerformanceModule,
    AppRoutingModule,
  ],
  // entryComponents: [OfflineSnackComponent],
  providers: [APP_PROVIDERS, AUTH_PROVIDERS, PerformanceMonitoringService, EMULATOR_PROVIDERS],
  bootstrap: [AppComponent],
})
export class AppModule {}
