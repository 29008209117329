import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { FeatureAuthService } from './feature-auth.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private featureAuth: FeatureAuthService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log('[FeatureGuard] featureName:', next.data.featureName);
    return this.featureAuth.isEnabled(next.data.featureName);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.featureAuth.isEnabled(route.data.featureName);
  }
}
