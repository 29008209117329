/**
 * Appointment data model
 */
export interface Appointment {
  id?: string;
  start: string;
  email: string;
  details?: string;
  statusId: AppointmentStatus;
  with?: string;
  facility?: string;
  duration: number;
  name: string;
  type?: AppointmentType;
  updateTime?: string;
  patientKey: string;
}

/**
 * Pre-defined Appointment Status Enumeration
 * SCHEDULED = 0, CHECKED_IN, COMPLETED, NO_SHOW, CANCELLED = 4, RE_SCHEDULED = 5, LATE_CANCELLED = 6
 */
export enum AppointmentStatus {
  SCHEDULED,
  CHECKED_IN,
  COMPLETED,
  NO_SHOW,
  CANCELLED,
  RE_SCHEDULED,
  LATE_CANCELLED,
}

/**
 * Appointment Type data model
 * Typically the types are part of Org Metadata configuration
 * Standard Values could be:
 * IE:Initial Eval
 * FU:Follow-Up
 * CS:Consultation
 * EP:Epidural
 * DX:Diagnostics
 *
 * minimal usage definition:
 * aType: AppointmentType = {
 *   shortCode: 'IE',
 *   name: 'Initial Eval'
 * }
 *
 */
export interface AppointmentType {
  shortCode: string;
  name: string;
  description?: string;
  color?: string;
  order?: number;
  blockNotification?: boolean;
}

/**
 * Pre-defined Appointment Events Enumeration
 */
export class AppointmentStatusDefs {
  static SCHEDULED = { id: AppointmentStatus.SCHEDULED, icon: 'event', color: 'primary', name: 'Scheduled' };
  static CHECKED_IN = { id: AppointmentStatus.CHECKED_IN, icon: 'sync', color: 'accent', name: 'Checked In' };
  static COMPLETED = { id: AppointmentStatus.COMPLETED, icon: 'check_circle', color: 'accent', name: 'Completed' };
  static NO_SHOW = { id: AppointmentStatus.NO_SHOW, icon: 'report_problem', color: 'warn', name: 'NO SHOW!' };
  static CANCELLED = { id: AppointmentStatus.CANCELLED, icon: 'remove_circle_outline', color: 'warn', name: 'Cancelled' };
  static RE_SCHEDULED = { id: AppointmentStatus.RE_SCHEDULED, icon: 'event', color: 'warn', name: 'Rescheduled' };
  static LATE_CANCELLED = { id: AppointmentStatus.LATE_CANCELLED, icon: 'assignment_late', color: 'warn', name: 'Late Cancelled' };

  /**
   * Get the Appointment Event by Status id
   * @param id
   * @returns
   */
  static get(id: number): { id: number; icon: string; color: string; name: string } {
    switch (id) {
      case AppointmentStatus.SCHEDULED:
        return this.SCHEDULED;
      case AppointmentStatus.CHECKED_IN:
        return this.CHECKED_IN;
      case AppointmentStatus.COMPLETED:
        return this.COMPLETED;
      case AppointmentStatus.NO_SHOW:
        return this.NO_SHOW;
      case AppointmentStatus.CANCELLED:
        return this.CANCELLED;
      case AppointmentStatus.RE_SCHEDULED:
        return this.RE_SCHEDULED;
      case AppointmentStatus.LATE_CANCELLED:
        return this.LATE_CANCELLED;
      default:
        return null;
    }
  }

  static getAll(): any[] {
    return [this.SCHEDULED, this.CHECKED_IN, this.COMPLETED, this.NO_SHOW, this.CANCELLED, this.RE_SCHEDULED, this.LATE_CANCELLED];
  }
}
