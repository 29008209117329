import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';

import { AppState } from './../../app.service';
import { AppVars } from './../../app.vars';

/**
 * AuthGuard activates the routes only when Auth Service has an authenticated logged in user
 */
@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  /**
   * Leverage the App State which holds the user logged in
   */
  constructor(private _as: AppState) {}

  /**
   * canActivate returns whether the next Activated Route be activated or not
   * @param next Activated Route
   * @param state Whether it can be activated or not
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const user = this._as.get(AppVars.USER_ACCOUNT);
    const securedAccount: boolean = user && user.email && user.approved; // user.emailVerified && / TODO: PRAPP-68 reimplement this
    const validRole = Object.values<boolean>(user.roles).reduce((a, b) => a || b) || false;
    console.log('[AuthGuard] user = ', user, securedAccount, validRole);
    return securedAccount; //  && validRole;
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    const user = this._as.get(AppVars.USER_ACCOUNT);
    return user && user.email && user.emailVerified && user.approved;
  }
}
