<!-- Sign In / Login card  -->
<mat-card fxLayout="column" fxLayoutGap="40px" *ngIf="!verificationFailed" class="login-card">
  <mat-card-header>
    <div class="card-title">Sign In</div>
    <!-- <div fxFlex fxLayoutAlign="end center">
      <a mat-button routerLink="/auth/register">No account? Sign up here...</a>
    </div> -->
  </mat-card-header>
  <form id="loginForm">
    <mat-card-content fxLayout="column">
      <div fxFlex="40">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Email Address</mat-label>
          <input matInput type="email" autocomplete="email" name="email" placeholder="Email Address" [(ngModel)]="email" id="emailInput" />
        </mat-form-field>
      </div>
      <div fxFlex="40">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Password</mat-label>
          <input
            matInput
            type="password"
            autocomplete="current-password"
            name="password"
            placeholder="Password"
            [(ngModel)]="password"
            (keyup.enter)="submit()"
            id="passwordInput"
          />
        </mat-form-field>
      </div>
      <div fxFlex="20">
        <p *ngIf="ackMessage || errorMessage">
          {{ ackMessage }}
          <mat-error> {{ errorMessage }}</mat-error>
          <button *ngIf="verificationFailed" mat-button (click)="resendVerificationEmail()">Resend verification email?</button>
        </p>
      </div>
    </mat-card-content>
  </form>

  <mat-card-actions fxLayoutAlign="end center">
    <span fxFlex fxLayoutAlign="start center">
      <a routerLink="/auth/reset-password" mat-button>Forgot Password?</a>
    </span>
    <button mat-stroked-button color="primary" (click)="submit()" [disabled]="showProgress">
      <span *ngIf="!showProgress; else spinner">SIGN IN</span>
      <ng-template #spinner>
        <mat-spinner color="primary" diameter="20" strokeWidth="2" style="margin: 8px"></mat-spinner>
      </ng-template>
    </button>
    <button mat-button (click)="reset()">RESET</button>
  </mat-card-actions>
</mat-card>

<!-- Email verification failed -->
<mat-card fxLayout="column" fxLayoutGap="40px" *ngIf="verificationFailed" class="login-card">
  <mat-card-header>
    <mat-icon matCardAvatar color="warn">error</mat-icon>
    <div class="card-title">Verification</div>
  </mat-card-header>
  <mat-card-content>
    <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
    <p *ngIf="ackMessage; else instructions">{{ ackMessage }}</p>
    <ng-template #instructions>Please click RESEND to get verification email.</ng-template>
  </mat-card-content>
  <mat-card-actions fxLayoutAlign="end center">
    <button mat-raised-button color="primary" (click)="reset()" *ngIf="ackMessage; else resend">OK</button>
    <ng-template #resend>
      <button mat-raised-button color="primary" (click)="resendVerificationEmail()">RESEND</button>
      <button mat-button (click)="reset()">CANCEL</button>
    </ng-template>
  </mat-card-actions>
</mat-card>
