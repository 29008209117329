import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { AppState } from './../../app.service';
import { AppVars } from './../../app.vars';
import { AuthService } from './../../core/auth/auth.service';

@Component({
  selector: 'mlc-join-org',
  templateUrl: './join-org.component.html',
  styleUrls: ['./join-org.component.scss']
})
export class JoinOrgComponent implements OnInit {
  orgCode = '';
  approved = false;
  verified = false;
  user: any;
  email = '';
  ackMessage = '';
  errorMessage = '';
  requestDate: any;
  verify = false;
  submitButtonName = 'JOIN';
  showProgress = false;

  constructor(private _appState: AppState, private router: Router, private auth: AuthService) {}

  ngOnInit() {
    this.user = this._appState.get(AppVars.USER_ACCOUNT);
    if (this.user && this.user.uid !== undefined && this.user.uid !== '' && this.user.uid) {
      this.email = this.user.email;
      this.verified = this.user.emailVerified;
    } else {
      this.exitOnError();
    }
    // Get the current Org association request object with status for the logged in user
    this.auth
      .getUserAccount()
      .pipe(take(1))
      .subscribe(user => {
        this.orgCode = user.orgCode;
        this.submitButtonName = user.orgCode ? 'SUBMIT' : 'JOIN';
        this.approved = user.approved;
        if (user.requestDate) {
          this.requestDate = user.requestDate;
        }
        // TODO: Improve efficiency by checking this in AuthService & avoid round route through join-org component
        if (this.approved) {
          console.log('TESTING: Inside Join-org.component => approved = ', this.approved);
          this.router.navigate(['/pages/dashboard']);
        }
      });
  }

  submit(): void {
    if (!this.orgCode) {
      return;
    }
    this.showProgress = true;

    const updateObject = {
      orgCode: this.orgCode,
      requestDate: this.requestDate,
      emailVerified: this.verified
    };
    if (!this.requestDate) {
      updateObject.requestDate = new Date();
    }

    // Update the user account with orgCode details
    this.auth
      .updateUserAccount(updateObject)
      .then(() => {
        this.ackMessage = 'Your request has been submitted. Please logout now and login once your account is activated.';
        this.showProgress = false;
      })
      .catch(err => {
        this.errorMessage = 'Your request cannot be submitted now. Please logout now and check with your organization admin.';
        console.log('Error while updating Org Code: ', this.email, ' => ', this.orgCode);
        this.showProgress = false;
      });
  }

  logout(): void {
    this.exitOnError();
  }

  private exitOnError(): void {
    this.auth.logout();
    this.router.navigate(['/auth/login']);
  }
}
