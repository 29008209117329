import { Injectable } from '@angular/core';
import { AngularFirePerformance } from '@angular/fire/performance';

@Injectable({
  providedIn: 'root',
})
export class PerfMonitorService {
  constructor(private ngPerf: AngularFirePerformance) {}

  /**
   * Setup a perf tracing observable. subscribe() to start and .unsubscribe() to stop
   * @param name Name of the Perf Metric to trace & observe
   * @param options Optional trace metrics, attributes
   */
  setTrace(name: string) {
    return this.ngPerf.trace(this.clean(name));
  }

  /**
   * Setup a perf tracing observable. subscribe() to start and .unsubscribe() to stop
   * @param name Name of the Perf Metric to trace & observe
   * @param options Optional trace metrics, attributes
   */
  // setTrace$(name: string, options?: TraceOptions) {
  //   return this.ngPerf.trace$(this.clean(name), options);
  // }

  // Remove any space or underscore characters in the name
  private clean(name: string): string {
    return name.replace(/ /g, '').replace(/_/g, '');
  }
}
