import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RoleAuthService } from './role-auth.service';

/**
 * RoleGuard activates the routes only when user role has appropriate mapped allowance
 */
@Injectable()
export class RoleGuard implements CanActivate {
  /**
   * Leverage the Auth Service which holds the user logged in
   * @param roleAuthService injected Role Auth Service
   */
  constructor(private roleAuthService: RoleAuthService, private router: Router) {}

  /**
   * canActivate returns whether the next Activated Route be activated or not
   * This checks unauthorized access, even if the menu/link is presented to the logged in user
   * @param next Activated Route
   * @param state Whether it can be activated or not
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // TODO: For production - no need to tap
    return this.roleAuthService.isAuthorized(next.routeConfig.path);

    // To debug
    // console.log('[RoleGuard] next.routeConfig.path = ', next.routeConfig.path);

    // For Development purposes only
    // return this.roleAuthService.isAuthorized(next.routeConfig.path).pipe(
    //   tap(authorized => {
    //     if (!authorized) {
    //       console.log(`[RoleGuard] User authorization = ${authorized} - this route ("${next.routeConfig.path}") is prevented!`);
    //     }
    //   })
    // );
  }
}
