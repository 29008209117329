import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/// Notify users about errors and other helpful stuff
export interface Msg {
  header?: string;
  content: string;
  style?: 'error' | 'info' | 'success';
}

@Injectable()
export class NotifyService {
  // Provided in MainAppModule
  private msgSource = new Subject<Msg | null>();
  private maxTimeout = 5000;
  msg = this.msgSource.asObservable();

  update(content: string, style: 'error' | 'info' | 'success' = 'info', autoClose?: number) {
    const msg: Msg = { content, style };
    this.msgSource.next(msg);
    if (autoClose) {
      autoClose = autoClose > this.maxTimeout ? this.maxTimeout : autoClose;
      return setTimeout(() => {
        this.clear();
      }, autoClose);
    }
  }

  info(content: string, autoClose = 3000) {
    this.update(content, 'info', autoClose);
  }

  success(content: string, autoClose = 3000) {
    this.update(content, 'success', autoClose);
  }

  error(content: string, autoClose = 0) {
    this.update(content, 'error', autoClose);
  }

  clear() {
    this.msgSource.next(null);
  }
}
