import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mlc-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss', './auth.page.scss']
})
export class AuthComponent implements OnInit {

  dateValue: any;

  constructor() {
    this.dateValue = new Date();
  }

  ngOnInit() {
  }

}
